<template>
<div class="" v-hammer:tap="displayProfileCard" v-hammer:swipe.all="hideProfileCard" @mouseover="displayProfileCard" @mouseleave="hideProfileCard" @mousewheel="hideProfileCard">
    <avatar style="" v-if="photoURL.split('/')[photoURL.split('/').length-1] === 'default_dp.png'" class="" :username="displayName" :size="40" />

    <vs-avatar v-else class="m-0 relative" :src="photoURL" :size="size" />
    <template v-if="!hideProfileHoverCard">
        <ProfileHoverCard v-if="partnerData" class="hoverCard" ref="profileCard" :data="profileData" :partnerData="partnerData" :displayUnFollow="displayUnFollow" />
        <ProfileHoverCard v-else class="hoverCard" ref="profileCard" :data="profileData" :displayUnFollow="displayUnFollow" />
    </template>
</div>
</template>

<script>
import ProfileHoverCard from './ProfileHoverCard'
import Avatar from 'vue-avatar'

export default {
    name: 'AvatarHolder',
    props: ['profileData', 'partnerData', 'photoURL', 'size', 'displayUnFollow', 'hideProfileHoverCard', 'displayName'],
    components: {
        ProfileHoverCard,
        Avatar
    },
    methods: {
        displayProfileCard() {
            if (!this.$refs.profileCard) return
            const profileCardStyle = this.$refs.profileCard.$el.style
            profileCardStyle.setProperty('opacity', 1)
            profileCardStyle.setProperty('visibility', 'visible')
            // profileCardStyle.setProperty('display', 'initial')

        },
        hideProfileCard() {
            if (!this.$refs.profileCard) return
            const profileCardStyle = this.$refs.profileCard.$el.style
            profileCardStyle.setProperty('opacity', 0)
            profileCardStyle.setProperty('visibility', 'hidden')
            // profileCardStyle.setProperty('display', 'none')

        }
    },
    // created(){
    //     console.log(this.hideProfileHoverCard)
    // }
}
</script>

<style scoped>
.hoverCard {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 300px;
    z-index: 100;
    transition: visibility 0.1s linear, opacity 0.1s linear;
}
</style>
