<template>
   <emoji-picker @emoji="insert" :search="search">
    <div 
      class="flex emoji-invoker justify-center align-center h-full" 
      slot="emoji-invoker" 
      slot-scope="{ events: { click: clickEvent } }" 
      @click.stop="clickEvent">
        <feather-icon svgClasses="h-8 w-8 cursor-pointer p-1 rounded" class="mr-2" icon="SmileIcon"></feather-icon>
    </div>
    <div slot="emoji-picker" slot-scope="{ emojis }">
      <div class="absolute z-10000 border w-64 h-96 overflow-scroll p-4 rounded bg-white shadow t-4 emoji-select-panel">
        <div class="flex">
          <input class="flex-1 rounded-full border py-2 px-4 outline-none focus:shadow-outline" type="text" v-model="search">
        </div>
        <div>
          <div v-for="(emojiGroup, category) in emojis" :key="category">
            <h5 class="text-grey-darker uppercase text-sm cursor-default mb-2 mt-4">{{ category }}</h5>
            <div class="flex flex-wrap justify-between emojis">
              <span
                class="p-1 cursor-pointer rounded hover:bg-grey-light focus:outline-none focus:shadow-outline flex items-center justify-center h-6 w-6"
                v-for="(emoji, emojiName) in emojiGroup"
                :key="emojiName"
                @click="insert(emoji)"
                :title="emojiName"
              >{{ emoji }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </emoji-picker>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker'

export default {
  name: 'EmojiPickerWrapper',
  components: {
    EmojiPicker
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    insert (emoji) {
      this.$emit('handleInsertEmoji', emoji)
    }
  }
}
</script>

<style lang="css" scoped>
.z-10000{
  z-index: 10000 !important;
}
.emoji-select-panel {
  overflow: scroll; 
  max-height: 400px;
}

@media only screen and (max-width: 600px) {
  .emoji-select-panel{
    right: 10px;
  }
}
</style>