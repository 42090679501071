<template>
<vs-card class="">
    <div class="flex leading-tight items-center">
        <avatar style="margin-left:5px;" v-if="data.photoURL.split('/')[data.photoURL.split('/').length-1] === 'default_dp.png'" class="border-2 border-solid border-white mr-2 my-1" :username="data.displayName" :size="42" />

        <vs-avatar class="mb-2" v-else size="45px" :src="data.photoURL" />
        <div class="ml-2">
            <div class="text-lg font-semibold profile-hover-display-name">{{ data.displayName }}</div>
            <span class="text-grey-darker text-sm" v-if="partnerData">{{ partnerData.partnerType | partnerTypeFormatter }}</span>
            <span class="text-grey-darker text-sm" v-else>{{ data.studyStatus }}</span>
            <feather-icon v-if="data.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-success rounded-full text-white"></feather-icon>
        </div>
        <div class="flex ml-auto">
            <vs-button type="flat" v-if="data.name !== currentUser.name" size="medium" icon="chat_bubble_outline" @click="goToChatRoom"></vs-button>
            <vs-button type="flat" class="ml-2" :to="bindUrl(data)" size="medium" icon="perm_identity"></vs-button>
        </div>
    </div>

    <!-- <div class="text-center mb-2" v-if="data.about">
      <p>"{{ data.about }}"</p>
    </div> -->
    <div class="ml-2 text-base">
        <div class="mt-2" v-if=" data.universityName">
            <div class="flex items-center">
                <feather-icon icon="BookOpenIcon" class="mr-3" svgClasses="h-4 w-4" />
                <p v-if="data.studyStatus === 'Student' || data.studyStatus === 'Current Student'"> Studying at <span class="font-semibold">{{ data.universityName }}</span></p>
                <p v-else-if="data.studyStatus === 'Professional'">Currently working <span class="font-semibold">{{ data.profession}} </span></p>
                <p v-else>Life-time learner</p>
            </div>
        </div>
        <div class="mt-2" v-if="data.followers">
          
            <div class="flex items-center">
                <feather-icon icon="UsersIcon" class="mr-3" svgClasses="h-4 w-4" />
                <span>Has {{ data.followers.items.length }} followers</span>
            </div>
        </div>
        <div class="mt-2" v-if="data.following">
            <div class="flex items-center">
                <feather-icon icon="UsersIcon" class="mr-3" svgClasses="h-4 w-4" />
                <span>Following {{ data.following.items.length }} people</span>
            </div>
        </div>
    </div>
</vs-card>
</template>

<script>
import Avatar from 'vue-avatar'
export default {
    name: 'ProfileHoverCard',
    props: ['data', 'partnerData', 'displayUnFollow'],
    components: {
        Avatar
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.userData
        }
    },
    methods: {
        bindUrl(data) {
            if (data.isPartner) {
                return `partner/${data.name}`
            } else {
                return `/profile/${data.name}`
            }
        },
        goToChatRoom() {
            this.$router.push(`/chat/${this.data.name}-${this.$store.state.auth.userData.name}`)
        },
       

    }
}
</script>

<style lang="scss" scoped>
.message-btn {
    width: 40px;
    height: 40px;
    border-radius: 10px;
}

.message-btn:hover {
    background-color: #ddd;
}
</style>
